import {Col, Container, Row} from "react-bootstrap";
import "./intro.css";

export default function MyIntro(){
    return(
        <Container className={"Container_introduction"} fluid>
            <Row>
                <Col md={6} sm={12} className={"Col_introduction"}>
                    <h4>Introducing eBS</h4>
                    <h1>Enterprise Buisness System, Bringing people and technology together
                        for the success of your organization</h1>
                </Col>
                <Col className="Col_intro_points">
                    <ul>
                        <li>A generalized ERP solution</li>
                        <li>Designed for simplicity and reliability</li>
                        <li>Access to online information in real time</li>
                        <li>Secure system with audit trail</li>
                        <li>Seamless interoperability</li>
                        <li>Offer flexibility and easy implementation</li>
                        <li>Deliver reliable after-sales service</li>
                        <li>Desktop application based on Microsoft Windows</li>
                        <li>Supports MS SQL Server as the database system</li>
                        <li>Trusted by clients since 2003</li>
                    </ul>
                </Col>
            </Row>
        </Container>
    );
}