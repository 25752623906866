import "./module.css"
import {Container, Row, Col, Card, Button} from "react-bootstrap";
import Popup from "./popup"
import {useState} from 'react'
import GLmoduleDark from "../media/modules/dark-general-ledger.png";
import BMmoduleDark from "../media/modules/dark-bank-management.png";
import APmoduleDark from "../media/modules/dark-account-payable.png";
import ICmoduleDark from "../media/modules/dark-inventory.png";
import ImoduleDark from "../media/modules/dark-invoicing.png";
import HRmoduleDark from "../media/modules/dark-human-resources.png";
import FMmoduleDark from "../media/modules/dark-fleet-management.png";
import GLmoduleLight from "../media/modules/light-general-ledger.png";
import BMmoduleLight from "../media/modules/light-bank-management.png";
import APmoduleLight from "../media/modules/light-account-payable.png";
import ICmoduleLight from "../media/modules/light-inventory.png";
import ImoduleLight from "../media/modules/light-invoicing.png";
import HRmoduleLight from "../media/modules/light-human-resources.png";
import FMmoduleLight from "../media/modules/light-fleet-management.png";


export default function Modules(){
    const [GLPopup, setGLPopup] = useState(false)
    const [BMPopup, setBMPopup] = useState(false)
    const [APPopup, setAPPopup] = useState(false)
    const [IPopup, setIPopup] = useState(false)
    const [ICPopup, setICPopup] = useState(false)
    const [HRPopup, setHRPopup] = useState(false)
    const [FMPopup, setFMPopup] = useState(false)

    return(
        <Container id={"module"} className={"Container_module"}>
            <Row className={"Row_moduleHeading"}>
                <h1>Tailored ERP Modules for Your Business Needs</h1>
            </Row>
            <Row xs={1} lg={2} xl={3} className="g-4 m-1 mb-5 p-1">
                <Col className="d-flex">
                    <Card className={"Card_Module"}>
                        <Card.Img variant="top" src={GLmoduleDark} />
                        <Card.Body>
                            <Card.Title>General Ledger Module</Card.Title>
                            <Button onClick={() => setGLPopup(true)}> Learn More </Button>
                            <Popup trigger={GLPopup} setTrigger={setGLPopup}>
                                <div>
                                    <Card.Img variant="top" src={GLmoduleLight} />
                                    <Card.Title>General Ledger Module</Card.Title>
                                </div>
                                <ul>
                                    <li>Users can customize the chart of account codes based on specific requirements,
                                        with support for memorandum accounts</li>
                                    <li>Predefined journals are available,
                                        with the option for users to create custom journals for recording transactions to the General Ledger
                                    </li>
                                    <li>Journals can be held in a pending state before posting to the GL,
                                        with posting controlled by user permissions and GL transaction periods
                                    </li>
                                    <li>Up to 10 analysis codes can be attached to each journal type</li>
                                    <li>User access to GL accounts can be controlled,
                                        with posting restrictions based on credit/debit type and amount</li>
                                    <li>Supports financial statements and reports tailored for management and auditors</li>
                                    <li>Automates the posting of retained earnings during the year-end closing process</li>
                                </ul>
                            </Popup>
                        </Card.Body>
                    </Card>
                </Col>
                <Col className="d-flex">
                    <Card className={"Card_Module"}>
                        <Card.Img variant="top" src={BMmoduleDark}/>
                        <Card.Body>
                            <Card.Title>Bank Management Module</Card.Title>
                            <Button onClick={() => setBMPopup(true)}> Learn More </Button>
                            <Popup trigger={BMPopup} setTrigger={setBMPopup}>
                                <div>
                                    <Card.Img variant="top" src={BMmoduleLight}/>
                                    <Card.Title>Bank Management Module</Card.Title>
                                </div>
                                <ul>
                                    <li>Support cheque printing</li>
                                    <li>Support bank reconciliation</li>
                                    <li>Support cheque deposit slips</li>
                                    <li>Support blank cheque issue register</li>
                                    <li>Return cheques register</li>
                                    <li>Customer PD cheque register</li>
                                </ul>
                            </Popup>
                        </Card.Body>
                    </Card>
                </Col>
                <Col className="d-flex">
                    <Card className={"Card_Module"}>
                        <Card.Img variant="top" src={APmoduleDark}/>
                        <Card.Body>
                            <Card.Title>Account Payable Module</Card.Title>
                            <Button onClick={() => setAPPopup(true)}> Learn More </Button>
                            <Popup trigger={APPopup} setTrigger={setAPPopup}>
                                <div>
                                    <Card.Img variant="top" src={APmoduleLight}/>
                                    <Card.Title>Account Payable Module</Card.Title>
                                </div>
                                <ul>
                                    <li>Monitor vendor activities through one dashboard</li>
                                    <li>Track purchasing from Purchase Requisition to GRN with purchase orders</li>
                                    <li>Fully automated payment process with system approvals including cheque
                                        printing
                                    </li>
                                    <li>Support multiple payment methods, including cheques, online fund transfer,
                                        direct payments
                                    </li>
                                    <li>Vendor invoice can be integrated with goods receiving notes</li>
                                    <li>Keep track of issued PD cheques</li>
                                </ul>
                            </Popup>
                        </Card.Body>
                    </Card>
                </Col>
                <Col className="d-flex">
                    <Card className={"Card_Module"}>
                        <Card.Img variant="top" src={ImoduleDark}/>
                        <Card.Body>
                            <Card.Title>Invoicing Module</Card.Title>
                            <Button onClick={() => setIPopup(true)}> Learn More </Button>
                            <Popup trigger={IPopup} setTrigger={setIPopup}>
                                <div>
                                    <Card.Img variant="top" src={ImoduleLight}/>
                                    <Card.Title>Invoicing Module</Card.Title>
                                </div>
                                <ul>
                                    <li>Facilitate five types of invoicing: primary sales invoicing,
                                        secondary sales invoicing, contract-based invoicing, service invoicing, and POS invoicing.</li>
                                    <li>Enable batch number, expiry date, and serial number tracking.</li>
                                    <li>Support non-stock item billing.</li>
                                    <li>Setup multiple pricing categories and discount schemes.</li>
                                    <li>Provide sales analysis by various categories.</li>
                                    <li>Offer credit control by department levels, for customers or customer sites.</li>
                                    <li>Link invoicing to sales orders.</li>
                                    <li>Track goods delivery dates by invoice item levels, including partial deliveries.</li>
                                </ul>
                            </Popup>
                        </Card.Body>
                    </Card>
                </Col>
                <Col className="d-flex">
                    <Card className={"Card_Module"}>
                        <Card.Img variant="top" src={ICmoduleDark}/>
                        <Card.Body>
                            <Card.Title>Inventory Control Module</Card.Title>
                            <Button onClick={() => setICPopup(true)}> Learn More </Button>
                            <Popup trigger={ICPopup} setTrigger={setICPopup}>
                                <div>
                                    <Card.Img variant="top" src={ICmoduleLight}/>
                                    <Card.Title>Inventory Control Module</Card.Title>
                                </div>
                                <ul>
                                    <li>Track expiry dates, manufacture date, batch numbers and serial numbers</li>
                                    <li>Support inventory management methods such as FIFO, LIFO, FEFO,
                                        Weighted average or by tracking number
                                    </li>
                                    <li>Support consignment tracking numbers and bin locations to maintain 3rd party
                                        logistics
                                    </li>
                                    <li>Support primary and secondary unit of measure</li>
                                </ul>
                            </Popup>
                        </Card.Body>
                    </Card>
                </Col>
                <Col className="d-flex">
                    <Card className={"Card_Module"}>
                        <Card.Img variant="top" src={HRmoduleDark} />
                        <Card.Body>
                            <Card.Title>Human Resource & Payroll Module</Card.Title>
                            <Button onClick={() => setHRPopup(true)}> Learn More </Button>
                            <Popup trigger={HRPopup} setTrigger={setHRPopup}>
                                <div>
                                    <Card.Img variant="top" src={HRmoduleLight}/>
                                    <Card.Title>Human Resource & Payroll Module</Card.Title>
                                </div>
                                <ul>
                                    <li>Employee personal records management</li>
                                    <li>Position and performance history tracking</li>
                                    <li>Attendance and leave management</li>
                                    <li>Payroll processing, including pay sheets and payslips</li>
                                    <li>Management of employee loans and bill payments</li>
                                    <li>Tax/EPF calculations with data export capabilities</li>
                                    <li>Salary upload file generation for national banks</li>
                                </ul>
                            </Popup>
                        </Card.Body>
                    </Card>
                </Col>
                <Col className="d-flex">
                    <Card className={"Card_Module"}>
                        <Card.Img variant="top" src={FMmoduleDark} />
                        <Card.Body>
                            <Card.Title>Fleet Management Module</Card.Title>
                            <Button onClick={() => setFMPopup(true)}> Learn More </Button>
                            <Popup trigger={FMPopup} setTrigger={setFMPopup}>
                                <div>
                                    <Card.Img variant="top" src={FMmoduleLight}/>
                                    <Card.Title>Fleet Management Module</Card.Title>
                                </div>
                                <ul>
                                    <li>Keep track of vehicle and driver master data</li>
                                    <li>Tracking vehicle service and maintenance</li>
                                    <li>Track vehicle license and insurance renewals</li>
                                    <li>Tracking driver test/training schedules</li>
                                    <li>Record vehicle maintenance/breakdown cost</li>
                                    <li>Tracking fuel consumption</li>
                                    <li>Monitor vehicle performance</li>
                                </ul>
                            </Popup>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}