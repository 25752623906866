import "./about.css"
import RCALogo from "../media/logo-web-transparent.png"
import {Container, Row, Col} from "react-bootstrap";

export default function About(){
    return(
        <Container id={"about"} className={"Container_about"} fluid>
            <Row>
                <Col lg={6} md={12} className={"Col_aboutLogo"}>
                    <img src={RCALogo} alt="" className={"img_about"}/>
                </Col>
                <Col className={"Col_about"}>
                    <h1>Meet RCA Technology</h1>
                    <p>Founded in 1997, RCA Technology has been a trusted provider of software applications for over two
                        decades.
                        Our mission is to provide reliable and enduring products that consistently exceed our clients'
                        expectations.
                        <br/><br/> We are guided by years of experience in delivering Enterprise Resource Planning (ERP)
                        solutions and have
                        been dedicated to empowering businesses of all sizes to streamline their operations, enhance
                        productivity,
                        and achieve sustainable growth.</p>
                </Col>
            </Row>
        </Container>
)
    ;
}