import {Container, Row, Col } from "react-bootstrap";
import "./home.css"
import IntroIdea from "../media/Intro-Idea2.png"

export default function MyHome(){
    return(
        <Container className={"Container_home"} id={"home"} fluid>
            <Row className="justify-content-md-center">
                <Col>
                    <img className={"img_intro"} src={IntroIdea} alt=""/>
                </Col>
            </Row>
        </Container>
    );
}