import React from 'react';
import './popup.css'

export default function Popup(props) {
    return (props.trigger) ? (
            <div className={'Popup'} onClick={() => props.setTrigger(false)}>
                <div className={"PopupInner"}>
                    {/*<button className={'PopupButton'}*/}
                    {/*    onClick={() => props.setTrigger(false)}>*/}
                    {/*    Close*/}
                    {/*</button>*/}
                    {props.children}
                </div>
            </div>
    ) : "";
}