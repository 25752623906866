import "./contact.css"
import {Container, Row, Col} from "react-bootstrap";
import LinkedInIcon from "../media/linkedin-app-white-icon.jpg"

export default function Contact(){
    return(
        <Container className={"Container_contact"} id={"contact"} fluid>
            <Row className={"Row_contact"}>
                <h3>Contact Us</h3>
                <Col className={"Col_contact"}>
                    <p>RCA Technology, 145/1, Meewathura, Peradeniya 20400, Sri Lanka</p>
                    <a href="tel:+94812076460">Tel: +94 0812 076460
                        <span style={{pointerEvents: 'none'}}>, </span>
                    </a>
                    <a href="tel:+94777366460"> +94 0777 366460</a>
                    <a href="mailto:rcat@slt.lk"><br />Email: rcat@slt.lk, </a>
                    <a href="mailto:info@rcat.lk">info@rcat.lk</a>
                </Col>
            </Row>
            <Row>
                <a href="https://www.linkedin.com/company/rca-technology/">
                    <img src={LinkedInIcon} alt="linkedIn"
                         style={{ width: '48px', height: 'auto', padding: '10px' }} />
                </a>
            </Row>
            <Row className="Row_credits">
                {/*<a href="https://www.pexels.com/photo/boat-sea-ocean-sailing-ship-7320/">*/}
                {/*    Photo Credits*/}
                {/*</a>*/}
                <p>© 2024 RCA Technology</p>
            </Row>
        </Container>
    );
}
/*⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀    ⣀⣴⡾
    ⠀⠀⠀⠀⠀    ⣀⣠⡴⠞⠉⣠⡞⠀⠀
    ⠀  ⣠⡶⠚⠛⣙⣭⠠⣤⣶⣯⠆⠀⠀⠀
    ⠀⢿⠀⠀⣼⠿⣫⣭⣠⣤⡶⠂⠀⠀⠀
    ⡺⠋⠀⣤⣸⣷⣶⡾⠖⠀⠀⠀⠀⠀⠀
    ⣄⣸⡿⣾⣿⡽⡄⠀⠀⠀⠀⠀⠀⠀⠀
    ⡙⠑⠙⠛⠉⠁⠀⠀⠀⠀
    VISION 24-06-09⠀⠀⠀⠀⠀
 */
