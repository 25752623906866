import './App.css';
import About from "./components/about";
import Contact from "./components/contact";
import Modules from "./components/module";
import MyNavbar from "./components/navbar";
import MyHome from "./components/home";
import MyIntro from "./components/intro";

function App() {
    return (
        <div>
            <MyNavbar />
            <MyHome />
            <MyIntro />
            <About />
            <Modules />
            <Contact />
        </div>
    );
}

export default App;
